/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import classnames from 'classnames';
import Link from 'next/link';

import { DOTS, usePagination } from './hooks';
import { PaginationProps } from './props';
import { PaginationContainer, PaginationItem, StyledIcon } from './styles';

export const Pagination: React.FC<PaginationProps> = (props) => {
  const {
    totalCount,
    siblingCount = 0,
    currentPage = 0,
    pageSize = 20,
    className = '',
    loading = false,
    href,
    hasQuery = false,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || !paginationRange || paginationRange.length < 2) {
    return null;
  }

  const lastPage = paginationRange[paginationRange?.length - 1];

  // const onNext = () => {
  //   if (lastPage === currentPage) return;
  //   onPageChange(currentPage + 1);
  // };

  // const onPrevious = () => {
  //   if (currentPage === 1) return;
  //   onPageChange(currentPage - 1);
  // };

  const RenderPaginationSection: React.FC<any> = ({ children }) => {
    return (
      <PaginationContainer
        className={classnames('', { [className]: className })}
      >
        {/* Left navigation arrow */}
        <Link
          href={
            currentPage === 2
              ? `${href}`
              : hasQuery
              ? `${href}&page=${currentPage - 1}`
              : `${href}?page=${currentPage - 1}`
          }
          shallow={true}
        >
          <PaginationItem
            className={classnames('', {
              disabled: currentPage === 1 || loading,
            })}
            // onClick={onPrevious}
          >
            <StyledIcon
              className={classnames('material-symbols-outlined text-xl', {
                disabled: currentPage === 1,
              })}
            >
              arrow_back
            </StyledIcon>
          </PaginationItem>
        </Link>

        {children}
        {/*  Right Navigation arrow */}
        <Link
          href={
            hasQuery
              ? `${href}&page=${currentPage + 1}`
              : `${href}?page=${currentPage + 1}`
          }
          shallow={true}
        >
          <PaginationItem
            className={classnames('', {
              disabled: currentPage === lastPage || loading,
            })}
            // onClick={onNext}
          >
            <StyledIcon
              className={classnames('material-symbols-outlined text-xl', {
                disabled: currentPage === lastPage,
              })}
            >
              arrow_forward
            </StyledIcon>
          </PaginationItem>
        </Link>
      </PaginationContainer>
    );
  };

  if (loading) {
    return (
      <RenderPaginationSection>
        <div
          style={{
            display: 'flex',
            position: 'relative',
          }}
        >
          {paginationRange.map((pageNumber: any) => {
            if (pageNumber === DOTS) {
              return (
                <PaginationItem
                  key={pageNumber}
                  className={classnames('dots', {
                    disabled: loading,
                  })}
                >
                  &#8230;
                </PaginationItem>
              );
            }
            return (
              <PaginationItem
                key={pageNumber}
                className={classnames('text-sm', {
                  selected: pageNumber === currentPage,
                  disabled: loading,
                })}
              >
                {pageNumber}
              </PaginationItem>
            );
          })}
        </div>
      </RenderPaginationSection>
    );
  }

  return (
    <RenderPaginationSection>
      {paginationRange.map((pageNumber: any) => {
        // If the pageItem is a DOT, render the DOTS unicode character
        if (pageNumber === DOTS) {
          return (
            <PaginationItem key={pageNumber} className="dots">
              &#8230;
            </PaginationItem>
          );
        }
        // Render our Page Pills
        return (
          <Link
            key={pageNumber}
            href={
              pageNumber < 2
                ? `${href}`
                : hasQuery
                ? `${href}&page=${pageNumber}`
                : `${href}?page=${pageNumber}`
            }
            shallow={true}
          >
            <PaginationItem
              key={pageNumber}
              className={classnames('text-sm', {
                selected: pageNumber === currentPage,
              })}
              // onClick={() => onPageChange(pageNumber as number)}
            >
              {pageNumber}
            </PaginationItem>
          </Link>
        );
      })}
      {/*  Right Navigation arrow */}
    </RenderPaginationSection>
  );
};

export default Pagination;
