import { TitleDividerProps } from './props';
import StyledTitle, { Box, TitleDividerWrapper } from './styles';

export const TitleDivider: React.FC<TitleDividerProps> = (props) => {
  // ======================= PROPS
  const { title, children } = props;

  // ======================= VIEWS
  return (
    <Box className="box">
      <TitleDividerWrapper className="box-wrapper">
        <StyledTitle>{title}</StyledTitle>
        <div className="box-children">{children}</div>
      </TitleDividerWrapper>
    </Box>
  );
};

// ======================= EXPORT
export * from './props';
export default TitleDivider;
