import { ReactNode } from 'react';
import Link from 'next/link';

import { BorderTitleProps } from './props';
import StyledTitle, { Box, TitleDividerWrapper } from './styles';
export const BorderTitle: React.FC<BorderTitleProps> = (props) => {
  // ======================= PROPS
  const { title, url, children } = props;

  const RenderRedirect: React.FC<{ children: ReactNode }> = (props) => {
    const { children } = props;
    return url ? <Link href={url}>{children}</Link> : <span>{children}</span>;
  };

  // ======================= VIEWS
  return (
    <Box className="box">
      <TitleDividerWrapper className="box-wrapper">
        <RenderRedirect>
          <StyledTitle $hoverEffect={!!url}>{title}</StyledTitle>
        </RenderRedirect>
        <div className="box-children">{children}</div>
      </TitleDividerWrapper>
    </Box>
  );
};

// ======================= EXPORT
export * from './props';
export default BorderTitle;
