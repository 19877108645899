import Link from 'next/link';

import { BreadcrumbProps } from './props';
import StyledBreadcrumb, {
  StyledBreadcrumbHolder,
  StyledBreadcrumbItem,
  StyledBreadcrumbText,
  StyledLastText,
} from './styles';

export const Breadcrumb: React.FC<BreadcrumbProps> = (props) => {
  // ======================= PROPS
  const { options } = props;

  // ======================= VIEWS
  return (
    <StyledBreadcrumb className="Breadcrumb">
      <StyledBreadcrumbHolder>
        {options.map((opt) => {
          return (
            <StyledBreadcrumbItem key={opt.title}>
              {opt.url ? (
                <Link href={opt.url} key={opt.url}>
                  <StyledBreadcrumbText className="text-sm">
                    {opt.title}
                  </StyledBreadcrumbText>
                </Link>
              ) : (
                <StyledLastText key={opt.url} className="text-sm">
                  {opt.title}
                </StyledLastText>
              )}
            </StyledBreadcrumbItem>
          );
        })}
      </StyledBreadcrumbHolder>
    </StyledBreadcrumb>
  );
};

// ======================= EXPORT
export default Breadcrumb;
